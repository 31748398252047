.menu {
  @media screen and (max-width: @breakpoint-lg) {
    display: none;
  }

  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  padding-top: 10px;
  z-index: 20;
  transition: all 0.3s;
  &-active {
    background-color: @grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    padding-top: 0;
  }
  &-content {
    width: 100%;
    text-align: center;
    .menu-link {
      @media screen and (max-width: @breakpoint-xxl) and (min-width: calc(@breakpoint-xl + 1px)) {
        font-size: @font-size-lg-xxs;
      }
      @media screen and (max-width: @breakpoint-xl) and (min-width: calc(@breakpoint-lg + 1px)) {
        font-size: @font-size-base;
      }
      padding: 5px 0;
      text-align: center;
      color: white;
      font-weight: @font-weight-semi-bold;
      font-family: 'champagne';
      font-size: @font-size-lg-xs;
      text-decoration: none;
      &:hover {
        transition: 0.2s linear;
      }
      &-active {
        color: darken(#fff, 15%);
      }
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        right: 100%;
        top: 20px;
        background: @red;
        height: 2px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover:before {
        right: 0;
      }
    }
    .flag-wrapper {
      width: 30px;
      margin: auto;
      cursor: pointer;
    }
  }
  &-logo {
    > div {
      @media screen and (max-width: @breakpoint-xl) {
        width: 100%;
      }
      width: 65%;
    }
  }
}

.menu-overlay {
  @media screen and (max-width: @breakpoint-lg) {
    display: block;
  }
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  &-open {
    width: 100%;
  }
  &-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
  }
  &-open-btn {
    background: rgba(0, 0, 0, 0.4);
    border-top-left-radius: @border-radius-md;
    border-bottom-left-radius: @border-radius-md;
    position: fixed;
    z-index: 50;
    top: 10px;
    right: 0;
  }
  &-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .menu-link {
      display: block;
      padding: 5px 0;
      letter-spacing: 5px;
      color: white;
      font-family: 'champagne';
      font-size: @font-size-lg-sm;
    }
  }
}

.menu-icon {
  margin: 15px;
}

.reduced-menu-link {
  @media screen and (max-width: @breakpoint-xxl) and (min-width: calc(@breakpoint-xl + 1px)) {
    font-size: @font-size-lg-xxs;
  }
  @media screen and (max-width: @breakpoint-xl) and (min-width: calc(@breakpoint-lg + 1px)) {
    font-size: @font-size-base;
  }
  cursor: pointer;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: @font-weight-semi-bold;
  font-family: 'champagne';
  font-size: @font-size-lg-xs;
  text-decoration: none;
  &:hover {
    transition: 0.2s linear;
  }
  &-active {
    color: darken(#fff, 15%);
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    top: 20px;
    background: @red;
    height: 2px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  &:hover:before {
    right: 0;
  }
}
