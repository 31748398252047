#contact {
  background-color: @background-color-1;
}

.contact {
  @media screen and (max-width: @breakpoint-xxl) {
    width: 90%;
  }
  @media screen and (max-width: @breakpoint-md) {
    width: 100%;
  }
  @media screen and (max-width: @breakpoint-sm) {
    width: 100%;
    display: block;
  }
  font-size: @font-size-lg;
  width: 70%;
  display: flex;
  margin: auto;
  &-wrapper {
    &-form {
      padding: 25px 50px;
      background: #fff;
      transition: 0.2s ease-in-out;
      position: relative;
      z-index: 2;
      &:hover {
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      }
    }
    &-infos {
      display: flex;
      flex-direction: column;
      color: white;
      &-details {
        height: 100%;
        padding: 25px 50px;
        background: @red;
        transition: 0.2s ease-in-out;
        position: relative;
        @media screen and (max-width: @breakpoint-sm) {
          transform: none;
        }
        z-index: 2;
        transform: scale(1.1) translate(5px, 10px);
        &:hover {
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        }
      }
      &-network {
        height: 100%;
        padding: 25px 50px;
        background: @grey;
        transition: 0.2s ease-in-out;
        position: relative;
        @media screen and (max-width: @breakpoint-sm) {
          transform: none;
        }
        transform: translate(-40px, 20px);
        z-index: 1;
        &:hover {
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        }
      }
      &-title {
        text-align: center;
        * > h4 {
          color: white;
        }
      }
      &-item {
        text-align: center;
      }
    }
  }
  &-link {
    > div {
      position: relative;
    }
    padding: 5px 0;
    text-align: center;
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
      transition: 0.2s linear;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      right: 100%;
      top: 26px;
      background: @red;
      height: 2px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover:before {
      right: 0;
    }
  }
}
