.footer {
  &-container {
    @media screen and (max-width: @breakpoint-xxl) {
      padding: 10px 400px;
    }
    @media screen and (max-width: @breakpoint-xl) {
      padding: 10px 250px;
    }
    @media screen and (max-width: @breakpoint-md) {
      padding: 10px 100px;
    }
    @media screen and (max-width: @breakpoint-sm) {
      padding: 10px 50px;
    }
    background: @grey;
    font-size: @font-size-lg;
    padding: 10px 500px;
    color: white;
    * > h5 {
      color: white;
    }
  }
  &-logo {
    > div {
      width: 100%;
    }
  }
  &-contact {
    @media screen and (max-width: @breakpoint-sm) {
      border-right: none;
      text-align: center;
    }
    border-right: 2px @red solid;
  }
  &-links {
    @media screen and (max-width: @breakpoint-sm) {
      border-left: none;
    }
    border-left: 2px @red solid;
    text-align: center;
    &-item {
      > div {
        position: relative;
      }
      cursor: pointer;
      color: white;
      &:hover {
        color: white;
        transition: 0.2s linear;
      }
      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        right: 100%;
        top: 17px;
        background: @red;
        height: 2px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      &:hover:before {
        right: 0;
      }
    }
  }
  &-link {
    > div {
      position: relative;
      cursor: pointer;
    }
    padding: 5px 0;
    text-align: center;
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
      transition: 0.2s linear;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      right: 100%;
      top: 20px;
      background: @red;
      height: 2px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover:before {
      right: 0;
    }
  }
  &-legacy,
  &-copyright {
    margin-top: 30px;
    text-align: center;
    &-link {
      color: white;
      &:hover {
        color: @red;
      }
    }
  }
}
