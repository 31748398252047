body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
ul {
  padding: 0;
}
img {
  width: 100%;
}
.page-content {
  padding: 0 200px 50px 200px;
  font-size: 1.118rem;
  font-family: 'Myriad Variable Concept', sans-serif;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 576px) {
  .page-content {
    padding: 0 25px 50px 25px;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(576px + 1px)) {
  .page-content {
    padding: 0 50px 50px 50px;
  }
}
.page-content section {
  padding: 1.333rem 0;
  width: 100%;
}
@font-face {
  font-family: 'champagne';
  src: url('../base/fonts/champagne_limousines.ttf');
}
@font-face {
  font-family: 'Material Design Icons';
  src: url('../base/fonts/materialdesignicons-webfont.eot?v=4.9.95');
  src: url('../base/fonts/materialdesignicons-webfont.eot?#iefix&v=4.9.95') format('embedded-opentype'), url('../base/fonts/materialdesignicons-webfont.woff2?v=4.9.95') format('woff2'), url('../base/fonts/materialdesignicons-webfont.woff?v=4.9.95') format('woff'), url('../base/fonts/materialdesignicons-webfont.ttf?v=4.9.95') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 1rem;
}
h1 {
  font-family: 'champagne';
  font-weight: 600;
  font-size: 4rem;
  margin: 0;
  letter-spacing: 10px;
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: calc(768px - 1px)) {
  h1 {
    font-size: 2rem;
  }
}
h2 {
  font-family: 'Myriad Variable Concept', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 5px;
  margin: 0;
}
@media screen and (max-width: 992px) {
  h2 {
    font-size: 1.588rem;
  }
}
h3 {
  font-size: 3.2rem;
  line-height: 39px;
  color: #fff;
  margin: 0;
  letter-spacing: 10px;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 576px) {
  h3 {
    font-size: 1.588rem;
    letter-spacing: 3px;
  }
}
h4 {
  font-size: 1.588rem;
  font-weight: 600;
}
h5 {
  font-size: 2rem;
  font-weight: 400;
}
#contact {
  background-color: #f5f5f5;
}
.contact {
  font-size: 1.118rem;
  width: 70%;
  display: flex;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .contact {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .contact {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .contact {
    width: 100%;
    display: block;
  }
}
.contact-wrapper-form {
  padding: 25px 50px;
  background: #fff;
  transition: 0.2s ease-in-out;
  position: relative;
  z-index: 2;
}
.contact-wrapper-form:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.contact-wrapper-infos {
  display: flex;
  flex-direction: column;
  color: white;
}
.contact-wrapper-infos-details {
  height: 100%;
  padding: 25px 50px;
  background: #5c1411;
  transition: 0.2s ease-in-out;
  position: relative;
  z-index: 2;
  transform: scale(1.1) translate(5px, 10px);
}
@media screen and (max-width: 576px) {
  .contact-wrapper-infos-details {
    transform: none;
  }
}
.contact-wrapper-infos-details:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.contact-wrapper-infos-network {
  height: 100%;
  padding: 25px 50px;
  background: #251e20;
  transition: 0.2s ease-in-out;
  position: relative;
  transform: translate(-40px, 20px);
  z-index: 1;
}
@media screen and (max-width: 576px) {
  .contact-wrapper-infos-network {
    transform: none;
  }
}
.contact-wrapper-infos-network:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.contact-wrapper-infos-title {
  text-align: center;
}
.contact-wrapper-infos-title * > h4 {
  color: white;
}
.contact-wrapper-infos-item {
  text-align: center;
}
.contact-link {
  padding: 5px 0;
  text-align: center;
  color: white;
  text-decoration: none;
}
.contact-link > div {
  position: relative;
}
.contact-link:hover {
  color: white;
  transition: 0.2s linear;
}
.contact-link:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  right: 100%;
  top: 26px;
  background: #5c1411;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.contact-link:hover:before {
  right: 0;
}
.privacy-policy,
.terms-conditions-of-use {
  padding: 80px 250px 50px;
}
@media screen and (max-width: 768px) {
  .privacy-policy,
  .terms-conditions-of-use {
    padding: 0 50px;
  }
}
.privacy-policy h1,
.terms-conditions-of-use h1 {
  font-family: 'Myriad Variable Concept', sans-serif;
  font-weight: 700;
  font-size: 2.118rem;
  color: #5c1411;
  margin: 0;
  letter-spacing: normal;
}
.privacy-policy h2,
.terms-conditions-of-use h2 {
  font-family: 'Myriad Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 1.824rem;
  color: #251e20;
  margin: 0;
  letter-spacing: normal;
}
.privacy-policy h3,
.terms-conditions-of-use h3 {
  font-family: 'Myriad Variable Concept', sans-serif;
  font-size: 1.294rem;
  margin: 0;
  letter-spacing: normal;
}
.privacy-policy ul,
.terms-conditions-of-use ul {
  padding: 0 50px;
}
.privacy-policy a,
.terms-conditions-of-use a {
  color: #251e20;
}
.privacy-policy a:hover,
.terms-conditions-of-use a:hover {
  color: #5c1411;
}
.privacy-policy button,
.terms-conditions-of-use button {
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.118rem;
  background: #5c1411;
  color: white;
  border: 2px #5c1411 solid;
  padding: 10px;
  border-radius: 9px;
  transition: 0.3s ease-in-out;
}
.privacy-policy button:hover,
.terms-conditions-of-use button:hover,
.privacy-policy button:active,
.terms-conditions-of-use button:active {
  color: #5c1411;
  background: white;
}
.footer-container {
  background: #251e20;
  font-size: 1.118rem;
  padding: 10px 500px;
  color: white;
}
@media screen and (max-width: 1600px) {
  .footer-container {
    padding: 10px 400px;
  }
}
@media screen and (max-width: 1200px) {
  .footer-container {
    padding: 10px 250px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 10px 100px;
  }
}
@media screen and (max-width: 576px) {
  .footer-container {
    padding: 10px 50px;
  }
}
.footer-container * > h5 {
  color: white;
}
.footer-logo > div {
  width: 100%;
}
.footer-contact {
  border-right: 2px #5c1411 solid;
}
@media screen and (max-width: 576px) {
  .footer-contact {
    border-right: none;
    text-align: center;
  }
}
.footer-links {
  border-left: 2px #5c1411 solid;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .footer-links {
    border-left: none;
  }
}
.footer-links-item {
  cursor: pointer;
  color: white;
}
.footer-links-item > div {
  position: relative;
}
.footer-links-item:hover {
  color: white;
  transition: 0.2s linear;
}
.footer-links-item:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  right: 100%;
  top: 17px;
  background: #5c1411;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.footer-links-item:hover:before {
  right: 0;
}
.footer-link {
  padding: 5px 0;
  text-align: center;
  color: white;
  text-decoration: none;
}
.footer-link > div {
  position: relative;
  cursor: pointer;
}
.footer-link:hover {
  color: white;
  transition: 0.2s linear;
}
.footer-link:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  right: 100%;
  top: 20px;
  background: #5c1411;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.footer-link:hover:before {
  right: 0;
}
.footer-legacy,
.footer-copyright {
  margin-top: 30px;
  text-align: center;
}
.footer-legacy-link,
.footer-copyright-link {
  color: white;
}
.footer-legacy-link:hover,
.footer-copyright-link:hover {
  color: #5c1411;
}
#gallery {
  background-color: #f5f5f5;
}
.gallery-card {
  font-size: 1rem;
  background: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: max-content;
  border-left: 5px solid #5c1411;
}
.gallery-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.gallery-card-text-wrapper {
  padding: 25px 25px;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(4px);
}
.image-modal .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0;
}
@media screen and (max-width: 576px) {
  .image-modal .ant-modal-content {
    margin-top: -15%;
  }
  .image-modal .ant-modal-content .ant-modal-close-x * > svg {
    fill: white;
  }
}
.image-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.image-modal .ant-modal-content .ant-modal-body .description-wrapper {
  background: white;
  padding: 24px;
  text-align: justify;
}
.image-modal .ant-modal-content .ant-modal-body .description-wrapper .time-wrapper {
  font-weight: 600;
  opacity: 0.5;
}
.gallery-more-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.118rem;
  background: #5c1411;
  color: #f5f5f5;
  border: 2px #5c1411 solid;
  border-radius: 9px;
  transition: 0.3s ease-in-out;
}
.gallery-more-wrapper:hover,
.gallery-more-wrapper:active {
  transform: scale(1.05);
  color: #5c1411;
  background: #f5f5f5;
}
.gallery-more-wrapper:hover * > span,
.gallery-more-wrapper:active * > span {
  color: #5c1411;
}
.albums-wrapper {
  background-color: #f5f5f5;
  padding: 70px 250px 50px;
}
@media screen and (max-width: 768px) {
  .albums-wrapper {
    padding: 0 50px;
  }
}
.albums-wrapper .album-element {
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 576px) {
  .albums-wrapper .album-element {
    flex-direction: column;
  }
}
.albums-wrapper .album-element-color {
  background-color: rgba(37, 30, 32, 0.5);
  height: 100%;
  width: 100%;
}
.albums-wrapper .album-element-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 0;
}
.albums-wrapper .album-element-title > h3 {
  color: white;
  line-height: 4rem;
}
.albums-wrapper .album-element-title-right {
  text-align: right;
  align-items: flex-end;
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .albums-wrapper .album-element-title-right {
    margin-right: 20px;
  }
}
.albums-wrapper .album-element-title-left {
  text-align: left;
  align-items: flex-start;
  margin-left: 50px;
}
@media screen and (max-width: 768px) {
  .albums-wrapper .album-element-title-left {
    margin-left: 20px;
  }
}
.albums-wrapper .album-element-right {
  border-left: 10px solid #5c1411;
}
.albums-wrapper .album-element-left {
  border-right: 10px solid #5c1411;
}
.albums-wrapper .album-element:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  z-index: 5;
}
@media screen and (max-width: 576px) {
  .albums-wrapper .album-element:hover {
    margin-bottom: 200px;
  }
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 36px;
  width: 36px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(20vh);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(-10%);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(13%);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: .8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 0 #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: background 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #337ab7;
  transform: scale(1.1);
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform 0.45s ease-out;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: .8em;
    padding: 5px 10px;
  }
}
.image-gallery .image-gallery-slides .image-gallery-image {
  min-height: 600px;
}
.image-gallery .image-gallery-icon {
  filter: none;
  -webkit-filter: none;
}
.image-gallery .image-gallery-left-nav {
  font-size: 4rem;
}
.image-gallery .image-gallery-left-nav svg {
  display: none;
}
.image-gallery .image-gallery-left-nav:before {
  font-family: 'Material Design Icons';
  content: '\F141';
  text-shadow: none;
  color: white;
  opacity: 0.5;
}
.image-gallery .image-gallery-right-nav {
  font-size: 4rem;
}
.image-gallery .image-gallery-right-nav svg {
  display: none;
}
.image-gallery .image-gallery-right-nav:before {
  font-family: 'Material Design Icons';
  content: '\F142';
  color: white;
  text-shadow: none;
  line-height: 36px;
  opacity: 0.5;
}
.image-gallery .image-gallery-left-nav:hover::before,
.image-gallery .image-gallery-right-nav:hover::before {
  opacity: 1;
}
.image-gallery .image-gallery-bullet:hover {
  background: #251e20;
}
.image-gallery .image-gallery-bullet.active {
  background: #5c1411;
}
.images-grid {
  text-align: center;
  margin: auto;
  width: 100%;
}
.images-grid .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.images-grid .height-one {
  padding-top: 100%;
}
.images-grid .height-two {
  padding-top: 50%;
}
.images-grid .height-three {
  padding-top: 33.3333%;
}
.images-grid .cover {
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.images-grid .cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.118rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}
.images-grid .cover-text > p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../images/img/backgrounds/home_background.jpg');
  background-size: cover;
}
.home-container .logo-wrapper {
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home-container .icon-wrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;
  animation: home-icon 1.5s linear infinite;
}
.home-container .icon-wrapper i {
  cursor: pointer;
}
@keyframes home-icon {
  0% {
    padding-bottom: 15px;
  }
  50% {
    padding-bottom: 30px;
  }
  100% {
    padding-bottom: 15px;
  }
}
.menu {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  padding-top: 10px;
  z-index: 20;
  transition: all 0.3s;
}
@media screen and (max-width: 992px) {
  .menu {
    display: none;
  }
}
.menu-active {
  background-color: #251e20;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  padding-top: 0;
}
.menu-content {
  width: 100%;
  text-align: center;
}
.menu-content .menu-link {
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: 600;
  font-family: 'champagne';
  font-size: 1.588rem;
  text-decoration: none;
}
@media screen and (max-width: 1600px) and (min-width: calc(1200px + 1px)) {
  .menu-content .menu-link {
    font-size: 1.294rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(992px + 1px)) {
  .menu-content .menu-link {
    font-size: 1rem;
  }
}
.menu-content .menu-link:hover {
  transition: 0.2s linear;
}
.menu-content .menu-link-active {
  color: #d9d9d9;
}
.menu-content .menu-link:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 20px;
  background: #5c1411;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.menu-content .menu-link:hover:before {
  right: 0;
}
.menu-content .flag-wrapper {
  width: 30px;
  margin: auto;
  cursor: pointer;
}
.menu-logo > div {
  width: 65%;
}
@media screen and (max-width: 1200px) {
  .menu-logo > div {
    width: 100%;
  }
}
.menu-overlay {
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
@media screen and (max-width: 992px) {
  .menu-overlay {
    display: block;
  }
}
.menu-overlay-open {
  width: 100%;
}
.menu-overlay-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}
.menu-overlay-open-btn {
  background: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  position: fixed;
  z-index: 50;
  top: 10px;
  right: 0;
}
.menu-overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.menu-overlay-content .menu-link {
  display: block;
  padding: 5px 0;
  letter-spacing: 5px;
  color: white;
  font-family: 'champagne';
  font-size: 1.824rem;
}
.menu-icon {
  margin: 15px;
}
.reduced-menu-link {
  cursor: pointer;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: 600;
  font-family: 'champagne';
  font-size: 1.588rem;
  text-decoration: none;
}
@media screen and (max-width: 1600px) and (min-width: calc(1200px + 1px)) {
  .reduced-menu-link {
    font-size: 1.294rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(992px + 1px)) {
  .reduced-menu-link {
    font-size: 1rem;
  }
}
.reduced-menu-link:hover {
  transition: 0.2s linear;
}
.reduced-menu-link-active {
  color: #d9d9d9;
}
.reduced-menu-link:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 20px;
  background: #5c1411;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.reduced-menu-link:hover:before {
  right: 0;
}
.page-title-container {
  padding-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-container * > h1 {
  background: #f5f5f5;
}
.page-title-wrapper {
  text-align: center;
  z-index: 0;
  position: relative;
  min-height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-wrapper {
    min-height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-wrapper {
    min-height: 120px;
  }
}
.page-title-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid rgba(0, 0, 0, 0.85);
  z-index: -1;
  animation: page-title-animation 1s;
}
@media screen and (max-width: 576px) {
  .page-title-rop {
    border-left: 2px solid rgba(0, 0, 0, 0.85);
  }
}
.page-title-rop-left {
  left: 44%;
}
.page-title-rop-center-left {
  left: 48%;
}
.page-title-rop-center-right {
  left: 52%;
}
.page-title-rop-right {
  left: 56%;
}
.page-title-light-container {
  margin-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-light-container * > h1 {
  background: #251e20;
}
.page-title-light-wrapper {
  z-index: 0;
  position: relative;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-light-wrapper {
    height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-light-wrapper {
    height: 120px;
  }
}
.page-title-light-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid rgba(0, 0, 0, 0.85);
  z-index: -1;
  animation: page-title-animation 1s;
}
@media screen and (max-width: 576px) {
  .page-title-light-rop {
    border-left: 2px solid rgba(0, 0, 0, 0.85);
  }
}
.page-title-light-rop-left {
  left: 44%;
}
.page-title-light-rop-center-left {
  left: 48%;
}
.page-title-light-rop-center-right {
  left: 52%;
}
.page-title-light-rop-right {
  left: 56%;
}
.page-title-dark-container {
  margin-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-dark-container * > h1 {
  color: #fff;
  background: #251e20;
}
.page-title-dark-wrapper {
  z-index: 0;
  position: relative;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-dark-wrapper {
    height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-dark-wrapper {
    height: 120px;
  }
}
.page-title-dark-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid #fff;
  z-index: -1;
  animation: page-title-animation 1s;
}
@media screen and (max-width: 576px) {
  .page-title-dark-rop {
    border-left: 2px solid rgba(0, 0, 0, 0.85);
  }
}
.page-title-dark-rop-left {
  left: 44%;
}
.page-title-dark-rop-center-left {
  left: 48%;
}
.page-title-dark-rop-center-right {
  left: 52%;
}
.page-title-dark-rop-right {
  left: 56%;
}
#presentation {
  background-color: #f5f5f5;
  background-image: url('../images/img/backgrounds/presentation_background.png');
  background-size: contain;
}
.profile {
  font-size: 1.118rem;
  background: #fff;
  width: 70%;
  padding: 25px 50px;
  flex-direction: column;
  margin: auto;
  transition: 0.2s ease-in-out;
  border-left: 10px solid #5c1411;
}
@media screen and (max-width: 768px) {
  .profile {
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .profile {
    font-size: 0.8824rem;
    padding: 25px;
  }
}
.profile:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.profile-image {
  display: block;
  width: 100%;
  height: auto;
}
.profile-image-vertical-border {
  border-left: 5px solid #5c1411;
  margin-left: 12px;
  position: absolute;
  height: 100%;
}
.profile-image-horizontal-border {
  border-bottom: 5px solid #5c1411;
  margin-bottom: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.profile-contact-button {
  margin-top: 10px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.118rem;
  background: #5c1411;
  color: white;
  border: 2px #5c1411 solid;
  padding: 10px;
  border-radius: 9px;
  transition: 0.3s ease-in-out;
}
.profile-contact-button:hover,
.profile-contact-button:active {
  color: #5c1411;
  background: white;
}
#services {
  background-color: #251e20;
}
.service-desc {
  text-align: center;
  color: white;
  margin-top: 40px;
}
.service-element {
  height: 200px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
@media screen and (max-width: 576px) {
  .service-element {
    flex-direction: column;
  }
}
.service-element-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 0;
}
.service-element-title > h3 {
  color: white;
}
@media screen and (max-width: 576px) {
  .service-element-title {
    width: 100%;
  }
}
.service-element-title-right {
  align-items: flex-start;
  margin-left: 50px;
}
@media screen and (max-width: 768px) {
  .service-element-title-right {
    margin-left: 20px;
  }
}
.service-element-title-left {
  align-items: flex-end;
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .service-element-title-left {
    margin-right: 20px;
  }
}
.service-element-right {
  border-right: 10px solid #5c1411;
}
.service-element-left {
  border-left: 10px solid #5c1411;
}
@media screen and (max-width: 576px) {
  .service-element {
    border-left: none;
    border-right: none;
  }
  .service-element:hover {
    margin-bottom: 200px;
    transform: none;
  }
}
.service-element:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}
@media screen and (max-width: 576px) {
  .service-element:hover {
    margin-bottom: 200px;
  }
}
.service-element:hover .service-element-description {
  opacity: 1;
  transform: scaleX(1);
}
@media screen and (max-width: 576px) {
  .service-element:hover .service-element-description {
    width: 100%;
    transform: scaleY(1) translateY(25px);
  }
}
.service-element-description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  width: 33%;
  height: 100%;
  transform: scaleX(0);
  background-color: rgba(92, 20, 17, 0.75);
  color: #fff;
  opacity: 0;
  transition: 0.8s ease-in-out;
}
@media screen and (max-width: 768px) {
  .service-element-description {
    font-size: 0.8824rem;
    width: 40%;
  }
}
@media screen and (max-width: 576px) {
  .service-element-description {
    width: 100%;
    transform: scaleX(1) scaleY(0) translateY(25px);
    transition: 0.2s ease-in-out;
  }
}
.service-element-description-item {
  text-align: center;
  margin: 10px auto;
}
.service-element-description-left {
  text-align: left;
  transform-origin: 0% 100%;
}
@media screen and (max-width: 576px) {
  .service-element-description-left {
    transform-origin: 100% 0%;
  }
}
.service-element-description-right {
  text-align: right;
  transform-origin: 100% 0%;
}
@media screen and (max-width: 576px) {
  .service-element-description-right {
    transform-origin: 100% 0%;
  }
}
