@import (less) '../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

.image-gallery {
  .image-gallery-slides {
    .image-gallery-image {
      min-height: 600px;
    }
  }

  .image-gallery-icon {
    filter: none;
    -webkit-filter: none;
  }
  .image-gallery-left-nav {
    font-size: @font-size-page-title;
    svg {
      display: none;
    }
    &:before {
      font-family: @font-icon;
      content: '\F141';
      text-shadow: none;
      color: white;
      opacity: 0.5;
    }
  }
  .image-gallery-right-nav {
    font-size: @font-size-page-title;
    svg {
      display: none;
    }
    &:before {
      font-family: @font-icon;
      content: '\F142';
      color: white;
      text-shadow: none;
      line-height: 36px;
      opacity: 0.5;
    }
  }

  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    opacity: 1;
  }

  .image-gallery-bullet {
    &:hover {
      background: @grey;
    }
    &.active {
      background: @red;
    }
  }
}
