#gallery {
  background-color: @background-color-1;
}

.gallery-card {
  font-size: @font-size-base;
  background: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: max-content;
  border-left: 5px solid @red;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &-text-wrapper {
    padding: 25px 25px;
  }
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(4px);
}
.image-modal {
  .ant-modal-content {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0;
    @media screen and (max-width: @breakpoint-sm) {
      margin-top: -15%;
      .ant-modal-close-x {
        * > svg {
          fill: white;
        }
      }
    }

    .ant-modal-body {
      padding: 0;
      .description-wrapper {
        background: white;
        padding: 24px;
        text-align: justify;
        .time-wrapper {
          font-weight: @font-weight-semi-bold;
          opacity: 0.5;
        }
      }
    }
  }
}
.gallery-more-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  text-transform: uppercase;
  cursor: pointer;
  font-size: @font-size-lg;
  background: @red;
  color: @background-color-1;
  border: 2px @red solid;
  border-radius: @border-radius-md;
  transition: 0.3s ease-in-out;
  &:hover,
  &:active {
    transform: scale(1.05);
    color: @red;
    background: @background-color-1;
    * > span {
      color: @red;
    }
  }
}

.albums-wrapper {
  background-color: @background-color-1;
  padding: 70px 250px 50px;
  @media screen and (max-width: @breakpoint-md) {
    padding: 0 50px;
  }

  .album {
    &-element {
      height: 300px;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: @breakpoint-sm) {
        flex-direction: column;
      }
      cursor: pointer;
      transition: 0.2s ease-in-out;
      position: relative;
      z-index: 1;
      &-color {
        background-color: fade(@grey, 50%);
        height: 100%;
        width: 100%;
      }
      &-title {
        > h3 {
          color: white;
          line-height: 4rem;
        }
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &-right {
          text-align: right;
          align-items: flex-end;
          @media screen and (max-width: @breakpoint-md) {
            margin-right: 20px;
          }
          margin-right: 50px;
        }
        &-left {
          text-align: left;

          align-items: flex-start;
          @media screen and (max-width: @breakpoint-md) {
            margin-left: 20px;
          }
          margin-left: 50px;
        }
        padding: 50px 0;
      }
      &-right {
        border-left: 10px solid @red;
      }
      &-left {
        border-right: 10px solid @red;
      }

      &:hover {
        @media screen and (max-width: @breakpoint-sm) {
          margin-bottom: 200px;
        }
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
        z-index: 5;
      }
    }
  }
}
