#services {
  background-color: @background-color-2;
}

.service {
  &-desc {
    text-align: center;
    color: white;
    margin-top: 40px;
  }
  &-element {
    height: 200px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: @breakpoint-sm) {
      flex-direction: column;
    }
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &-title {
      > h3 {
        color: white;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: @breakpoint-sm) {
        width: 100%;
      }
      &-right {
        align-items: flex-start;
        @media screen and (max-width: @breakpoint-md) {
          margin-left: 20px;
        }
        margin-left: 50px;
      }
      &-left {
        align-items: flex-end;
        @media screen and (max-width: @breakpoint-md) {
          margin-right: 20px;
        }
        margin-right: 50px;
      }
      padding: 50px 0;
    }
    &-right {
      border-right: 10px solid @red;
    }
    &-left {
      border-left: 10px solid @red;
    }
    @media screen and (max-width: @breakpoint-sm) {
      border-left: none;
      border-right: none;
      &:hover {
        margin-bottom: 200px;
        transform: none;
      }
    }
    &:hover {
      @media screen and (max-width: @breakpoint-sm) {
        margin-bottom: 200px;
      }
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      transform: scale(1.05);
      .service-element-description {
        opacity: 1;
        transform: scaleX(1);
        @media screen and (max-width: @breakpoint-sm) {
          width: 100%;
          transform: scaleY(1) translateY(25px);
        }
      }
    }
    &-description {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      width: 33%;
      height: 100%;
      transform: scaleX(0);
      background-color: fade(@red, 75%);
      color: @white;
      opacity: 0;
      transition: 0.8s ease-in-out;
      @media screen and (max-width: @breakpoint-md) {
        font-size: @font-size-sm;
        width: 40%;
      }
      @media screen and (max-width: @breakpoint-sm) {
        width: 100%;
        transform: scaleX(1) scaleY(0) translateY(25px);
        transition: 0.2s ease-in-out;
      }
      &-item {
        text-align: center;
        margin: 10px auto;
      }
      &-left {
        @media screen and (max-width: @breakpoint-sm) {
          transform-origin: 100% 0%;
        }
        text-align: left;
        transform-origin: 0% 100%;
      }
      &-right {
        @media screen and (max-width: @breakpoint-sm) {
          transform-origin: 100% 0%;
        }
        text-align: right;
        transform-origin: 100% 0%;
      }
    }
  }
}
