.images-grid {
  text-align: center;
  margin: auto;
  width: 100%;
  .background {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .height-one {
    padding-top: 100%;
  }

  .height-two {
    padding-top: 50%;
  }

  .height-three {
    padding-top: 33.3333%;
  }

  .cover {
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    &-text {
      right: 0;
      left: 0;
      bottom: 0;
      color: white;
      font-size: @font-size-lg;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
      text-align: center;
      > p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
