.privacy-policy,
.terms-conditions-of-use {
  padding: 80px 250px 50px;
  @media screen and (max-width: @breakpoint-md) {
    padding: 0 50px;
  }
  h1 {
    font-family: @basic-font;
    font-weight: @font-weight-bold;
    font-size: @font-size-lg-xl;
    color: @red;
    margin: 0;
    letter-spacing: normal;
  }

  h2 {
    font-family: @basic-font;
    font-weight: @font-weight-semi-bold;
    font-size: @font-size-lg-sm;
    color: @grey;
    margin: 0;
    letter-spacing: normal;
  }

  h3 {
    font-family: @basic-font;
    font-size: @font-size-lg-xxs;
    margin: 0;
    letter-spacing: normal;
  }

  ul {
    padding: 0 50px;
  }

  a {
    color: @grey;
    &:hover {
      color: @red;
    }
  }

  button {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: @font-size-lg;
    background: @red;
    color: white;
    border: 2px @red solid;
    padding: 10px;
    border-radius: @border-radius-md;
    transition: 0.3s ease-in-out;
    &:hover,
    &:active {
      color: @red;
      background: white;
    }
  }
}
