#presentation {
  background-color: @background-color-1;
  background-image: url('../../images/img/backgrounds/presentation_background.png');
  background-size: contain;
}

.profile {
  @media screen and (max-width: @breakpoint-md) {
    width: 90%;
  }
  @media screen and (max-width: @breakpoint-sm) {
    font-size: @font-size-sm;
    padding: 25px;
  }
  font-size: @font-size-lg;
  background: #fff;
  width: 70%;
  padding: 25px 50px;
  flex-direction: column;
  margin: auto;
  transition: 0.2s ease-in-out;
  border-left: 10px solid @red;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &-image {
    display: block;
    width: 100%;
    height: auto;
    &-vertical-border {
      border-left: 5px solid @red;
      margin-left: 12px;
      position: absolute;
      height: 100%;
    }
    &-horizontal-border {
      border-bottom: 5px solid @red;
      margin-bottom: 12px;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &-contact-button {
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: @font-size-lg;
    background: @red;
    color: white;
    border: 2px @red solid;
    padding: 10px;
    border-radius: @border-radius-md;
    transition: 0.3s ease-in-out;
    &:hover,
    &:active {
      color: @red;
      background: white;
    }
  }
}
