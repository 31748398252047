.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../../images/img/backgrounds/home_background.jpg');
  background-size: cover;

  .logo-wrapper {
    position: absolute;
    top: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    margin-top: auto;
    animation: home-icon 1.5s linear infinite;
    i {
      cursor: pointer;
    }
  }
}

@keyframes home-icon {
  0% {
    padding-bottom: 15px;
  }
  50% {
    padding-bottom: 30px;
  }
  100% {
    padding-bottom: 15px;
  }
}
